import React from 'react'
import Bredcom from '../Bredcom/Main'
import Header from '../headerone/Main'
import First from '../mencoursedetails/FirstAnalysis'
import Brand from '../menhomethree/Brand'

function Main() {
  return (
    <>
        <Header/>
        <Bredcom title="Home" subtitle="Analysis & Interpretation" />
        <First/>
        {/* <Brand/> */}
    </>
  )
}

export default Main