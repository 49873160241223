import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import pdf1 from '../../assets/img/calendar/Annual_Calender2025.pdf'
import pdf2 from '../../assets/img/calendar/Revised_Calender2024.pdf'
import pdf3 from '../../assets/img/calendar/AnnualCalendar-2024.pdf'
import img from '../../assets/img/bg/prepare2.png'
import axios from 'axios';
import { BASE_URL } from '../../iaspassionConfig'

const faqItems = [
    {
        id: 'headingThree',
        question: 'What does IAS Passion Institute offer?',
        answer: 'The institute likely offers coaching programs for the UPSC Civil Services Examination (CSE), with a focus on General Studies Paper-I and other GS papers. They might also provide guidance on optional subjects, essay writing, and interview skills.',
    },
    {
        id: 'headingOne',
        question: 'Is IAS Passion Institute good for IAS preparation?',
        answer: 'The effectiveness of any coaching institute depends on individual learning styles and preferences. Consider factors like faculty experience, study materials, success rates (if available), and student reviews before making a decision.',
    },
    {
        id: 'headingTwo',
        question: 'What are the fees of IAS Passion Institute?',
        answer: 'Fee structures can vary depending on the program duration and type. Contact the institute directly for their latest fee information.',
    },
];

function FirstPrepare() {

    const [activeIndex, setActiveIndex] = useState(null);
    const [prelims, setprelims] = useState([]);
    const [aptitudetest, setaptitudetest] = useState([]);
    const [mains, setmains] = useState([]);
    const [essay, setEssay] = useState([]);
    const [mainsstudies, setmainsstudies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchprelims = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/examprelims`);
                setprelims(response.data.data); // Adjust according to your API response structure
                // setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchprelims();

        const fetchtest = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/examAptitudeTest`);
                setaptitudetest(response.data.data); // Adjust according to your API response structure
                //   setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchtest();

        const fetchpaper = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/examMain`);
                setmains(response.data.data); // Adjust according to your API response structure
                // setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchpaper();

        const fetchessay = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/examEssay`);
                setEssay(response.data.data); // Adjust according to your API response structure
                //   setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };
        fetchessay();

        const fetchmainstudies = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/examStudies`);
                setmainsstudies(response.data.data); // Adjust according to your API response structure
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };
        fetchmainstudies();
    }, []); // Empty dependency array means this effect runs once after initial render

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    const handleToggle = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const renderParagraphs = (paragraphs) => {
        return <div dangerouslySetInnerHTML={{ __html: paragraphs }} />;
    };

    return (
        <>
            <section className="project-detail">
                <div className="container">
                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-12 col-md-12 col-sm-12">
                                <h2>How to Prepare: Preliminary Examination(Prelims), Main Examination(Mains), Interview</h2>
                                <div className="upper-box">
                                    <div className="single-item-carousel owl-carousel owl-theme">
                                        <figure className="image">
                                            <img src={img} alt="" />
                                        </figure>
                                    </div>
                                </div>
                                <div className="inner-column">
                                    {/* <div className="course-meta2 review style2 clearfix mb-30">
                                        <ul className="left">
                                            <li>
                                                <div className="author">
                                                    <div className="thumb">
                                                        <img src="assets/img/bg/testi_avatar.jpg" alt="image" />
                                                    </div>
                                                    <div className="text">
                                                        <Link to="/single-courses-2">Robto Jone</Link>
                                                        <p>Teacher</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="categories">
                                                <div className="author">
                                                    <div className="text">
                                                        <a href="#" className="course-name">
                                                            Photoshop
                                                        </a>
                                                        <p>Categories</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <ul className="right">
                                            <li className="price">&#x20b9; 59.00</li>
                                        </ul>
                                    </div> */}
                                    <div>
                                        <h3>How To Prepare:</h3>
                                        <h4 id='prelims'>Preliminary Examination (Prelims):</h4>
                                        <ul className='py-2 px-4'>
                                            <li className='my-2' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black", textAlign: "justify" }}>i. <b>12-18 Months Before Exam:</b> Focus on building a strong foundation in General Studies subjects like History, Geography, Polity, Economy, Science & Technology, and Environment. NCERT textbooks are a great starting point.Prelims help weed out a large number of applicants, ensuring only the most well-prepared candidates proceed to the main exam.The syllabus for Prelims often emphasizes general studies and foundational knowledge across various disciplines like History, Geography, Polity, Economy, and Science.Starting early allows you to build a strong foundation in each subject, making it easier to grasp complex concepts later.You can develop a comprehensive study plan that allocates sufficient time for each subject, incorporates mock tests, and factors in breaks to avoid burnout.</li>
                                            <li className='my-2' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black", textAlign: "justify" }}>ii. <b>6-12 Months Before Exam:</b> Start practicing previous years' question papers for both GS Paper I and CSAT. Analyze question patterns, identify your strengths and weaknesses, and work on areas that need improvement.Identify trends and recurring themes. Are there specific topics or areas frequently tested? Understanding these patterns allows you to prioritize your revision effectively.Gauge the level of difficulty of the questions. This helps you understand the expectations and the level of detail required in your answers.Familiarize yourself with the various question formats used in the exam (e.g., multiple choice, matching type, comprehensions).</li>
                                            <li className='my-2' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black", textAlign: "justify" }}>iii. <b>3-6 Months Before Exam:</b> Intensify your practice with mock tests offered by coaching institutes or online platforms. This will help you develop time management skills and get comfortable with the exam format.Analyzing your performance in mock tests is just as important as taking them. Here's what to focus on:Track your mistakes across different mock tests. Are there specific topics or question types that consistently challenge you? Prioritize revision of these areas.Analyze your time allocation across different sections in the mock tests. Are you spending too much time on certain questions? Refine your time management strategy to ensure you attempt all sections effectively.Taking numerous mock tests under timed conditions helps you get accustomed to the pressure of the actual exam. You'll learn to manage your anxiety and approach the exam with a calm and focused mind.</li>
                                            <li className='my-2' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black", textAlign: "justify" }}>iv. <b>Last 1-3 Months Before Exam:</b> Revise key concepts, focus on current affairs, and practice answer writing for essay writing and precis writing (CSAT).Move away from introducing entirely new concepts at this point.  Focus on revising the vast amount of information you've accumulated throughout your preparation journey.Rely on the study materials and resources that have proven effective for you so far. Revisit your notes, textbooks, and compilations of important formulae or facts.Use revision techniques like mind maps, flashcards, or spaced repetition to solidify your understanding of key concepts and improve recall during the exam.Dedicate a specific time slot daily to staying updated on current national and international affairs. This is crucial because many Prelims exams emphasize recent developments and their connection to broader topics in the syllabus. Focus on credible sources like newspapers, news websites, or magazines recommended by experts or toppers.</li>
                                        </ul>
                                        <h5>General Studies</h5>
                                        <table className="table table-bordered mb-40">
                                            <thead style={{ backgroundColor: '#FF7350' }}>
                                                <tr style={{ color: 'white', fontSize: '16px' }}>
                                                    <th>Subject</th>
                                                    <th>Topic</th>
                                                    <th>Description</th>
                                                    <th>Syllabus</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {prelims.map((doc) => (
                                                    <tr key={doc.ID}>
                                                        <td>
                                                            <a href={doc.subject}>{doc.subject}</a>
                                                        </td>
                                                        <td>{doc.topic}</td>
                                                        <td>{doc.dsc}</td>
                                                        <td>{doc.syllabus}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <h5>Aptitude Test</h5>
                                        <table className="table table-bordered mb-40">
                                            <thead style={{ backgroundColor: '#FF7350' }}>
                                                <tr style={{ color: 'white', fontSize: '16px' }}>
                                                    <th>Subject</th>
                                                    <th>Topic</th>
                                                    <th>Description</th>
                                                    <th>Syllabus</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {aptitudetest.map((doc) => (
                                                    <tr key={doc.ID}>
                                                        <td>
                                                            <a href={doc.subject}>{doc.subject}</a>
                                                        </td>
                                                        <td>{doc.topic}</td>
                                                        <td>{doc.dsc}</td>
                                                        <td>{doc.syllabus}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <h4 id='mains'>Main Examination (Mains):</h4>
                                        <ul className='py-2 px-4'>
                                            <li className='my-2' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black", textAlign: "justify" }}>i. <b>After Prelims Result (Assuming you qualify):</b> Start strategizing for the Mains exam. Choose your Optional Subjects wisely based on your interest and aptitude. Unlike the objective-type Prelims, the Main Exam is a more comprehensive evaluation that assesses your analytical skills, writing ability, and in-depth knowledge of the subjects relevant to your chosen field.The Main Exam typically involves writing essays, comprehensive answers to analytical questions, and tackling case studies. It tests your ability to critically analyze information, present well-structured arguments, and propose solutions to complex problems.The specific subjects tested in the Main Exam will depend on the exam you're targeting (e.g., Civil Services, Management Entrance Exams). Ensure you have a clear understanding of the syllabus and weightage of each subject.Obtain a detailed syllabus for the Main Exam specific to your chosen field. Analyze the topics covered in each subject and understand their weightage in the overall exam.</li>
                                            <li className='my-2' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black", textAlign: "justify" }}>ii. <b>6-12 Months Before Mains:</b> Focus on in-depth study of General Studies subjects using standard reference books and subject-specific materials. Start preparing for your Optional Subjects. Thoroughly study the syllabus and exam pattern for the Mains exam you're targeting. This includes the specific subjects tested, the weightage of each subject, and the types of questions asked (essays, case studies, problem-solving).Analyze toppers' answer copies (if available) to understand the expected level of detail, structure, and presentation for successful answers.Identify frequently tested topics within each subject. This helps you prioritize your study efforts. Gauge the complexity of questions typically asked to prepare yourself for the level of analysis required.</li>
                                            <li className='my-2' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black", textAlign: "justify" }}>iii. <b>3-6 Months Before Mains:</b> Intensify your answer writing practice for all Mains papers (Essay, GS, and Optional). Practice answer structuring, content organization, and effective writing style.Revisit your notes, textbooks, and reference materials. Focus on areas you identified as needing improvement during the previous stage (6-12 months before Mains).Dedicate time to in-depth study of specific topics within each subject. This allows you to develop a nuanced understanding and prepare for in-depth analysis in the exam.Make answer writing practice the cornerstone of your preparation during this phase.Increase the frequency of your answer writing practice sessions. Aim for at least 2-3 sessions per week, covering various question types (essays, case studies, problem-solving).Practice writing answers within the stipulated time frame, simulating the exam environment. This helps you develop time management skills and avoid last-minute rushes.</li>
                                            <li className='my-2' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black", textAlign: "justify" }}>iv. <b>Last 1-3 Months Before Mains:</b> Focus on revision, current affairs updates, and mock tests specifically designed for the Mains exam format. Focus on revising your meticulously prepared notes, compilations of key facts and formulae, and subject summaries. Dedicate time to revising specific topics within each subject, ensuring you have a clear understanding of even the less frequently tested areas.Continue taking mock tests at regular intervals, but you can slightly decrease the frequency compared to the previous stage (3-6 months before Mains).The primary focus should be on analyzing your performance in mock tests and identifying any lingering weaknesses. Refine your time management skills and address any areas where you consistently struggle.Prioritize activities that help you manage stress and maintain a positive mindset. Techniques like meditation, deep breathing exercises, or spending time in nature can be beneficialWhile focused revision is crucial, prioritize adequate sleep, a healthy diet, and short breaks to avoid burnout. Ensure you get enough rest to be at your cognitive best on exam day.Practice positive self-talk to boost your confidence. Remind yourself of the effort you've put in and visualize success.</li>
                                        </ul>
                                        <h4>Compulsory Papers - Paper A(Indian Language) & Paper B(English)</h4>
                                        <p className='my-0' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>Type of Questions</b>: Descriptive
                                        </p>
                                        <p className='my-0' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>Total Marks</b>: 300
                                        </p>
                                        <p className='my-0' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>Duration</b>: 3 hours
                                        </p>
                                        <p style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>Qualifying Marks</b>: Typically around 25% (75 marks)
                                        </p>
                                        <table className="table table-bordered mb-40">
                                            <thead style={{ backgroundColor: '#FF7350' }}>
                                                <tr style={{ color: 'white', fontSize: '16px' }}>
                                                    <th>Paper</th>
                                                    <th>Topic</th>
                                                    <th>Description</th>
                                                    <th>Syllabus</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {mains.map((doc) => (
                                                    <tr key={doc.ID}>
                                                        <td>
                                                            <a href={doc.paper}>{doc.paper}</a>
                                                        </td>
                                                        <td>{doc.title}</td>
                                                        <td>{doc.dsc}</td>
                                                        <td>{doc.syllabus}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <h4>Essay</h4>
                                        <p className='my-0' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>Total Marks</b>: 300
                                        </p>
                                        <p className='my-0' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>Duration</b>: 3 hours
                                        </p>
                                        <p className='my-0' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>Number of Essays</b>: Candidates are required to write two essays.
                                        </p>
                                        <p style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>Choice of Topics</b>: Generally, there are multiple topics provided in two sections, and candidates must choose one topic from each section to write their essays.
                                        </p>

                                        <h4>Key Components:</h4>
                                        <h5>Choice of Topics:</h5>
                                        <p className='my-0' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            Candidates are given a choice of topics from various domains such as politics, society, economics, environment, culture, technology, and more.
                                        </p>
                                        <p style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            Topics may be abstract, philosophical, or based on current events and issues.
                                        </p>
                                        <h5>Essay Structure:</h5>
                                        <p className='my-0' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>Introduction:</b>Briefly introduce the topic, define key terms, and outline the scope of the essay.
                                        </p>
                                        <p className='my-0' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>Body:</b>Develop the main arguments, provide evidence, examples, and illustrations. Discuss various perspectives and counterarguments.
                                        </p>
                                        <p style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>Conclusion:</b>Summarize the key points, restate your position, and provide a closing thought or solution.
                                        </p>
                                        <h5>Writing Style:</h5>
                                        <p className='my-0' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            Clarity and coherence in presenting ideas.
                                        </p>
                                        <p className='my-0' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            Logical flow and structure of arguments.
                                        </p>
                                        <p className='my-0' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            Use of relevant examples and evidence to support points.
                                        </p>
                                        <p className='my-0' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            Balanced and unbiased discussion of different perspectives.
                                        </p>
                                        <p style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            Avoidance of overly complex language; use simple and precise vocabulary.
                                        </p>

                                        <h5>Preparation Strategy:</h5>
                                        <p style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>
                                                Reading and Knowledge Building:
                                            </b>
                                            Read extensively on various topics, including current affairs, historical events, and philosophical issues.
                                            Refer to standard books, newspapers, magazines, and online resources.</p>
                                        <p style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>
                                                Practice Writing:
                                            </b>

                                            Regularly practice writing essays on diverse topics.
                                            Focus on improving the structure, coherence, and clarity of your essays.
                                            Time yourself while writing to simulate exam conditions.
                                        </p>
                                        <p style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>

                                                Feedback and Improvement:
                                            </b>

                                            Get your essays reviewed by peers, mentors, or teachers.
                                            Incorporate feedback to improve your writing style and content.
                                            Work on weak areas identified during reviews.
                                        </p>
                                        <p style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>

                                                Reading Model Essays:
                                            </b>

                                            Read model essays and analyze their structure, content, and style.
                                            Understand how successful candidates have tackled different topics.
                                        </p>
                                        <p style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>

                                                Developing Multiple Perspectives:
                                            </b>

                                            Practice thinking from different perspectives on a given topic.
                                            Develop the ability to argue both for and against an issue logically.
                                        </p>
                                        <p style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>

                                                Current Affairs Integration:
                                            </b>

                                            Integrate current events and recent developments into your essays.
                                            Stay updated with national and international news to provide relevant examples.</p>
                                        <table className="table table-bordered mb-40">
                                            <thead style={{ backgroundColor: '#FF7350' }}>
                                                <tr style={{ color: 'white', fontSize: '16px' }}>
                                                    <th>Topic</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {essay.map((doc) => (
                                                    <tr key={doc.ID}>
                                                        <td style={{ width: "30%" }}>{renderParagraphs(doc.title)}</td>
                                                        <td style={{ width: "70%" }}>{renderParagraphs(doc.dsc)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                        <h4>General Studies</h4>
                                        <h5>Structure</h5>
                                        <p className='my-0' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>
                                                Total Marks:
                                            </b>
                                            250 marks
                                        </p>
                                        <p className='my-0' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>
                                            Duration:
                                            </b>
                                            3 hours
                                        </p>
                                        <p className='my-0' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>
                                            Question Type:
                                            </b>
                                            Descriptive/Essay-type
                                        </p>
                                        <p style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>
                                            Number of Questions:
                                            </b>
                                            Typically 20 questions
                                        </p>
                                        <h5>Examination Strategy</h5>
                                        <p className='my-0' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>
                                            Thorough Reading:
                                            </b>
                                            Understand and internalize the entire syllabus. Refer to NCERT books, standard textbooks, and reliable sources for in-depth knowledge.
                                        </p>
                                        <p className='my-0' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>
                                            Current Affairs:
                                            </b>
                                            Keep updated with current events related to heritage, culture, history, geography, and societal issues through newspapers, magazines, and online sources.
                                        </p>
                                        <p className='my-0' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>
                                            Practice Writing:
                                            </b>
                                            Regularly practice answer writing to manage time effectively and present answers in a coherent and structured manner.
                                        </p>
                                        <p style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>
                                            Mock Tests:
                                            </b>
                                            Take mock tests to assess your preparation level, identify weak areas, and improve writing speed.
                                        </p>
                                        <p style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>
                                            Diagrams and Maps:
                                            </b>
                                            Use diagrams, maps, and charts wherever applicable to enhance the presentation of answers and make them more illustrative.                                        </p>
                                        <table className="table table-bordered mb-40">
                                            <thead style={{ backgroundColor: '#FF7350' }}>
                                                <tr style={{ color: 'white', fontSize: '16px' }}>
                                                    <th>Exam Name</th>
                                                    <th>Topic</th>
                                                    <th>Syllabus</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {mainsstudies.map((doc) => (
                                                    <tr key={doc.ID}>
                                                        <td style={{ width: "20%" }}>{doc.syllabus}</td>
                                                        <td style={{ width: "30%" }}>{renderParagraphs(doc.topic)}</td>
                                                        <td style={{ width: "50%" }}>{renderParagraphs(doc.dsc)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <h4 id='interview'>Interview:</h4>
                                        <ul className='py-2 px-4'>
                                            <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black", textAlign: "justify" }}>i. <b>Throughout Preparation:</b> Practice clear and concise communication, hone your analytical and problem-solving skills, and stay updated on current events.Regularly practicing interview responses helps you articulate your thoughts clearly, concisely, and convincingly.The more you practice answering questions in a simulated interview setting, the more comfortable and confident you'll feel during the actual interview.Interview-style questions can expose areas where your knowledge might be shaky, prompting you to revisit those topics during your studies.Schedule mock interviews with friends who are also preparing for the exam or with mentors who have experience with the interview process.Simulate the actual interview environment as much as possible. This could involve dressing professionally and setting a time limit for responses.Prepare for a variety of questions typically asked in interviews for your chosen field. This might include questions about your motivations, academic background, current affairs, or hypothetical scenarios. After the mock interview, ask your peers or mentors for constructive feedback on your communication style, body language, and the content of your answers.</li>
                                            <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black", textAlign: "justify" }}>ii. <b>After Mains Result (Assuming you qualify):</b> Start preparing for the interview by working on self-introductions, mock interviews, and current affairs discussions.The interview goes beyond assessing your academic knowledge tested in the Mains exam. It evaluates your personality, communication skills, critical thinking abilities, and overall suitability for the role or position you're targeting.The interview format can vary depending on the specific exam or organization. It might involve individual interviews, panel interviews, or a combination of both.Demonstrate your in-depth understanding of the subjects relevant to your chosen field.These questions assess your problem-solving skills and approach to hypothetical scenarios you might encounter in your professional role.Stay updated on current events and be prepared to discuss their relevance to your field.Thoroughly research the specific role or position you're interviewing for. Learn about the organization's mission, values, and recent achievements.Dress professionally in a manner that aligns with the organization's culture.Prepare well-structured responses to common interview questions. Practice your delivery to ensure clarity, conciseness, and enthusiasm.</li>
                                            <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black", textAlign: "justify" }}>iii. <b>1-2 Months Before Interview:</b> Focus on in-depth analysis of your DAF (Detailed Application Form) and be prepared to answer questions related to your background, achievements, and motivations for joining the civil services.Schedule regular mock interviews with friends, mentors, or professional interview preparation services. Aim for at least 2-3 mock interviews per week. Participate in a variety of mock interview formats (individual, panel) to get comfortable with different scenarios.After each mock interview, analyze the feedback provided. Identify areas for improvement in your communication skills, body language, and content of your responses.Thoroughly research the organization's website. Learn about their mission, values, recent projects, and areas of focus. This knowledge can help you tailor your responses to demonstrate a genuine interest in the opportunity.Stay updated on current trends and developments relevant to the industry you're targeting. This demonstrates your awareness and potential value to the organization.</li>
                                        </ul>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FirstPrepare