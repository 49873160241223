import React from 'react'
import { Link } from 'react-router-dom'

function First() {
    return (
        <>
            <section className="inner-blog b-details-p pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="blog-details-wrap">
                                <div className="details__content pb-30">
                                    <h2>
                                        Keep Calm and Conquer UPSC Prelims.
                                    </h2>
                                    <div className="meta-info">
                                        <ul>
                                            <li>
                                                <i className="fal fa-eye" /> 100 Views
                                            </li>
                                            <li>
                                                <i className="fal fa-comments" /> 35 Comments
                                            </li>
                                            <li>
                                                <i className="fal fa-calendar-alt" /> 24th March 2023
                                            </li>
                                        </ul>
                                    </div>
                                    <p>
                                    Every morning, she would religiously cross out the dates on her calendar with a red marker. This ritual was meant to instill a sense of urgency and motivation. However, with just a week until D-day, it was only making her nervous. Would her years of hard work finally reap the ‘desired’ results? Would she be able to cross the competitive cut-offs, which seemed to be decreasing at an alarming rate over the years? Would she finally be able to leap to the next stage (‘Mains’) and put pen to paper after sacrificing innumerable pen refills over the months? These thoughts swirled in her mind like a whirlpool each night as she lay in bed, eyes closed, but the portal to worry wide open.


                                    </p>
                                    <p>
                                    You have studied all that you could over the last few months, but that is not enough. Prelims is not just a test of knowledge. It’s about how you can apply intelligent guesswork, and connect seemingly disparate information to arrive at logical answers. Most importantly, it is about how calm you can remain under immense pressure and time constraints. The best way to prepare now is to practice by taking mock full-length exams in the offline mode. This will enable you to practice your strategy in attempting the complete paper within the time limit. Tempted by the shiny new materials being posted on social media? The ‘last-minute tips and tricks to ace the UPSC Prelims 2024 Exam’, ‘Most important topics for IAS exam’, and you know the drill…Steer clear, avoid the temptation! With one week to go, be content with your preparation. Do not constantly worry about all that you could have read and accomplished if you had started earlier or chosen a better test series or any other reason that is making you question your past choices. Focus on the NOW. Revise what you have already studied; do not try to gather new materials at this stage. Trust yourself and your preparation.
                                    </p>
                                    <blockquote>
                                        <footer>By Kofi Annan </footer>
                                        <h3>
                                        The true civil servant is motivated by public purpose, not private gain.
                                        </h3>
                                    </blockquote>
                                    <p>
                                    You know all that pressure you put over yourself? About multiple attempts, being unemployed, the hydra-headed monster of self-doubt that plagues you-if you’ll clear it or invest another year…the thoughts of “what will people say?” Banish such thoughts. Tell yourself that “It’s just an exam”. It’s not bigger than your happiness & well-being. It really isn’t. Take regular breaks to recharge, relax, and talk to your parents or friends/mentors who encourage you.
                                    </p>
                                    <div className="details__content-img">
                                        <img src="assets/img/blog/b_details01.jpg" alt="" />
                                    </div>
                                    <p>
                                    As she crossed out the final day on the countdown- D-day of the exam, June 16th – with her bright red marker, she felt a mix of trepidation, but also relief and most importantly excitement! She was excited to get the opportunity to be able to finally put her preparation to the test after months of hard work. She recalled her mentor’s advice from the previous evening, ‘See a challenging Question in the paper? Smile. Walk in to the examination hall with a big smile on your face,” and that’s exactly what she planned to do! She had given her best, and now it was time to smile, stay calm and conquer UPSC Prelims 2024!
                                    </p>
                                    <figure>
                                        <img src="assets/img/blog/b_details02.jpg" alt="" />
                                        <p>
                                        Tempted by the shiny new materials being posted on social media? The ‘last-minute tips and tricks to ace the UPSC Prelims 2024 Exam’, ‘Most important topics for IAS exam’, and you know the drill…Steer clear, avoid the temptation! With one week to go, be content with your preparation. Do not constantly worry about all that you could have read and accomplished if you had started earlier or chosen a better test series or any other reason that is making you question your past choices. Focus on the NOW. Revise what you have already studied; do not try to gather new materials at this stage. Trust yourself and your preparation.
                                        </p>
                                    </figure>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="post__tag">
                                                <h5>Releted Tags</h5>
                                                <ul>
                                                    <li>
                                                        <a href="#">upsc</a>
                                                    </li>
                                                    <li>
                                                        <a href="#">civilservices</a>
                                                    </li>
                                                    <li>
                                                        <a href="#">policy</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="posts_navigation pt-35 pb-35">
                                    <div className="row align-items-center">
                                        <div className="col-xl-4 col-md-5">
                                            <div className="prev-link">
                                                <span>Prev Post</span>
                                                <h4>
                                                    <a href="#">Tips Minimalist</a>
                                                </h4>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-md-2 text-left text-md-center">
                                            <Link to="/blog" className="blog-filter">
                                                <img src="assets/img/icon/c_d01.png" alt="" />
                                            </Link>
                                        </div>
                                        <div className="col-xl-4 col-md-5">
                                            <div className="next-link text-left text-md-right">
                                                <span>next Post</span>
                                                <h4>
                                                    <a href="#">Less Is More</a>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="related__post mt-45 mb-85">
                                    <div className="post-title">
                                        <h4>Related Post</h4>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="related-post-wrap mb-30">
                                                <div className="post-thumb">
                                                    <img src="assets/img/blog/b_details03.jpg" alt="" />
                                                </div>
                                                <div className="rp__content">
                                                    <h3>
                                                        <a href="#">
                                                        Environment & Ecology Current Affairs.
                                                        </a>
                                                    </h3>
                                                    <p>
                                                    This newly discovered plant species steals nutrients from underground fungi, adding a unique entry to the botany section of UPSC preparation.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="related-post-wrap mb-30">
                                                <div className="post-thumb">
                                                    <img src="assets/img/blog/b_details04.jpg" alt="" />
                                                </div>
                                                <div className="rp__content">
                                                    <h3>
                                                        <a href="#">
                                                        Policy and Governance.
                                                        </a>
                                                    </h3>
                                                    <p>
                                                    Focuses on shaping India's development agenda and strategies, essential for understanding India's policy framework. Plays a pivotal role in addressing environmental issues through legal means. <br />
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="avatar__wrap text-center mb-45">
                                    <div className="avatar-img">
                                        <img src="assets/img/blog/comment/avatar.jpg" alt="" style={{borderRadius:'50%'}}/>
                                    </div>
                                    <div className="avatar__info">
                                        <h5>Rosalina William</h5>
                                        <div className="avatar__info-social">
                                            <a href="#">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-twitter" />
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-instagram" />
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-behance" />
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-linkedin" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="avatar__wrap-content">
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                                            enim ad minim veniam, quis nostrud exercitation ullamco laboris
                                            nisi ut aliquip ex ea commodo consequa aute irure dolor.
                                        </p>
                                    </div>
                                </div>
                                <div className="comment__wrap pb-45">
                                    <div className="comment__wrap-title">
                                        <h5>Comments</h5>
                                    </div>
                                    <div className="single__comment mb-35">
                                        <div className="comments-avatar">
                                            <img src="assets/img/blog/comment/c_01.png" alt="" />
                                        </div>
                                        <div className="comment-text">
                                            <div className="avatar-name mb-15">
                                                <h6>ALina Kelian</h6>
                                                <span>19th May 2021</span>
                                            </div>
                                            <p>
                                                The bee's knees bite your arm off bits and bobs he nicked it
                                                gosh gutted mate blimey, old off his nut argy bargy vagabond
                                                buggered dropped.
                                            </p>
                                            <a href="#" className="comment-reply mt-15">
                                                <i className="fas fa-reply" /> Reply
                                            </a>
                                        </div>
                                    </div>
                                    <div className="single__comment children mb-35">
                                        <div className="comments-avatar">
                                            <img src="assets/img/blog/comment/c_02.png" alt="" />
                                        </div>
                                        <div className="comment-text">
                                            <div className="avatar-name mb-15">
                                                <h6>
                                                    Rlex Kelian <i className="fas fa-bookmark" />
                                                </h6>
                                                <span>19th May 2021</span>
                                            </div>
                                            <p>
                                                Do one say wind up buggered bobby bite your arm off gutted
                                                mate, David victoria sponge cup of char chap fanny around.
                                            </p>
                                            <a href="#" className="comment-reply mt-15">
                                                <i className="fas fa-reply" /> Reply
                                            </a>
                                        </div>
                                    </div>
                                    <div className="single__comment">
                                        <div className="comments-avatar">
                                            <img src="assets/img/blog/comment/c_03.png" alt="" />
                                        </div>
                                        <div className="comment-text">
                                            <div className="avatar-name mb-15">
                                                <h6>Roboto Alex</h6>
                                                <span>21th May 2021</span>
                                            </div>
                                            <p>
                                                Baking cakes is cobblers wellies William geeza bits and bobs
                                                what a plonker it's your round,
                                            </p>
                                            <a href="#" className="comment-reply mt-15">
                                                <i className="fas fa-reply" /> Reply
                                            </a>
                                        </div>
                                    </div>
                                </div> */}
                                <div id="comments" className="comments-area  mt-45">
                                    <div id="respond" className="comment-respond">
                                        <h3 id="reply-title" className="comment-reply-title">
                                            Leave a Reply{" "}
                                            <small>
                                                <a rel="nofollow" id="cancel-comment-reply-link" href="/finco/?p=2112#respond" style={{ display: "none" }} >
                                                    Cancel reply
                                                </a>
                                            </small>
                                        </h3>
                                        <form action="http://wordpress.zcube.in/finco/wp-comments-post.php" method="post" id="commentform" className="comment-form" noValidate="" >
                                            <p className="comment-notes">
                                                <span id="email-notes">
                                                    Your email address will not be published.
                                                </span>{" "}
                                                Required fields are marked <span className="required">*</span>
                                            </p>
                                            <p className="comment-field">
                                                <i className="fas fa-user" />
                                                <input id="author" placeholder="Your Name" name="author" type="text" />
                                            </p>
                                            <p className="comment-field">
                                                <i className="fas fa-envelope" />
                                                <input id="email" placeholder="your-real-email@example.com" name="email" type="text" />
                                            </p>
                                            <p className="comment-field">
                                                <i className="fas fa-globe" />
                                                <input id="url" name="url" placeholder="http://your-site-name.com" type="text" />{" "}
                                            </p>
                                            <p className="comment-form-comment">
                                                <label htmlFor="comment">Comment</label>{" "}
                                                <textarea id="comment" name="comment" cols={45} rows={8} maxLength={65525} required="required" defaultValue={""} />
                                            </p>
                                            <p className="form-submit">
                                                <input name="submit" type="submit" id="submit" className="submit" defaultValue="Post Comment" />{" "}
                                                <input type="hidden" name="comment_post_ID" defaultValue={2112} id="comment_post_ID" />
                                                <input type="hidden" name="comment_parent" id="comment_parent" defaultValue={0} />
                                            </p>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-4">
                            <aside className="sidebar-widget">
                                <section id="search-3" className="widget widget_search">
                                    <h2 className="widget-title">Search</h2>
                                    <form role="search" method="get" className="search-form" action="http://wordpress.zcube.in/finco/">
                                        <label>
                                            <span className="screen-reader-text">Search for:</span>
                                            <input type="search" className="search-field" placeholder="Search …" defaultValue="" name="s" />
                                        </label>
                                        <input type="submit" className="search-submit" defaultValue="Search" />
                                    </form>
                                </section>
                                <section id="custom_html-5" className="widget_text widget widget_custom_html">
                                    <h2 className="widget-title">Follow Us</h2>
                                    <div className="textwidget custom-html-widget">
                                        <div className="widget-social">
                                            <a href="#">
                                                <i className="fab fa-twitter" />
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-pinterest-p" />
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-instagram" />
                                            </a>
                                            <a href="#">
                                                <i className="fab fa-wordpress" />
                                            </a>
                                        </div>
                                    </div>
                                </section>
                                <section id="categories-1" className="widget widget_categories">
                                    <h2 className="widget-title">Categories</h2>
                                    <ul>
                                        <li className="cat-item cat-item-16">
                                            <a href="#">Branding</a> (4)
                                        </li>
                                        <li className="cat-item cat-item-23">
                                            <a href="#">Corporat</a> (3)
                                        </li>
                                        <li className="cat-item cat-item-18">
                                            <a href="#">Design</a> (3)
                                        </li>
                                        <li className="cat-item cat-item-22">
                                            <a href="#">Gallery</a> (3)
                                        </li>
                                    </ul>
                                </section>
                                <section id="recent-posts-4" className="widget widget_recent_entries">
                                    <h2 className="widget-title">Recent Posts</h2>
                                    <ul>
                                        <li>
                                            <a href="#">
                                                User Experience Psychology And Performance Smshing
                                            </a>
                                            <span className="post-date">August 19, 2020</span>
                                        </li>
                                        <li>
                                            <a href="#">Monthly Web Development Up Cost Of JavaScript</a>
                                            <span className="post-date">August 19, 2020</span>
                                        </li>
                                        <li>
                                            <a href="#">
                                                There are many variation passages of like available.
                                            </a>
                                            <span className="post-date">August 19, 2020</span>
                                        </li>
                                    </ul>
                                </section>
                                <section id="tag_cloud-1" className="widget widget_tag_cloud">
                                    <h2 className="widget-title">Tag</h2>
                                    <div className="tagcloud">
                                        <a href="#" className="tag-cloud-link tag-link-28 tag-link-position-1" style={{ fontSize: "8pt" }} aria-label="app (1 item)" >
                                            app
                                        </a>
                                        <a href="#" className="tag-cloud-link tag-link-17 tag-link-position-2" style={{ fontSize: "8pt" }} aria-label="Branding (1 item)" >
                                            Branding
                                        </a>
                                        <a href="#" className="tag-cloud-link tag-link-20 tag-link-position-3" style={{ fontSize: "8pt" }} aria-label="corporat (1 item)" >
                                            corporat
                                        </a>
                                        <a href="#" className="tag-cloud-link tag-link-24 tag-link-position-4" style={{ fontSize: "16.4pt" }} aria-label="Design (2 items)" >
                                            Design
                                        </a>
                                        <a href="#" className="tag-cloud-link tag-link-25 tag-link-position-5" style={{ fontSize: "22pt" }} aria-label="gallery (3 items)" >
                                            gallery
                                        </a>
                                        <a href="#" className="tag-cloud-link tag-link-26 tag-link-position-6" style={{ fontSize: "8pt" }} aria-label="video (1 item)" >
                                            video
                                        </a>
                                        <a href="#" className="tag-cloud-link tag-link-29 tag-link-position-7" style={{ fontSize: "16.4pt" }} aria-label="web design (2 items)" >
                                            web design
                                        </a>
                                    </div>
                                </section>
                            </aside>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default First