import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import pdf1 from '../../assets/img/calendar/Annual_Calender2025.pdf'
import pdf2 from '../../assets/img/calendar/Revised_Calender2024.pdf'
import pdf3 from '../../assets/img/calendar/AnnualCalendar-2024.pdf'
import img from '../../assets/img/bg/examdteatils.jpg'
import axios from 'axios';
import { BASE_URL } from '../../iaspassionConfig'

// const faqItems = [
//     {
//         id: 'headingThree',
//         question: 'What does IAS Passion Institute offer?',
//         answer: 'The institute likely offers coaching programs for the UPSC Civil Services Examination (CSE), with a focus on General Studies Paper-I and other GS papers. They might also provide guidance on optional subjects, essay writing, and interview skills.',
//     },
//     {
//         id: 'headingOne',
//         question: 'Is IAS Passion Institute good for IAS preparation?',
//         answer: 'The effectiveness of any coaching institute depends on individual learning styles and preferences. Consider factors like faculty experience, study materials, success rates (if available), and student reviews before making a decision.',
//     },
//     {
//         id: 'headingTwo',
//         question: 'What are the fees of IAS Passion Institute?',
//         answer: 'Fee structures can vary depending on the program duration and type. Contact the institute directly for their latest fee information.',
//     },
// ];

function FirstNew() {

    const [activeIndex, setActiveIndex] = useState(null);
    const [faqItems, setFAQItems] = useState([])
    const [documents, setDocuments] = useState([]);
    const [notification, setnotification] = useState([]);
    const [result, setresult] = useState([]);

    const handleToggle = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchFaqs = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/faq`);
                setFAQItems(response.data.data); // Adjust according to your API response structure
                // setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchFaqs();

        const fetchDocuments = async () => {
            try {
              const response = await axios.get(`${BASE_URL}/examcalendar`);
              setDocuments(response.data.data); // Adjust according to your API response structure
            //   setLoading(false);
            } catch (err) {
              setError(err.message);
              setLoading(false);
            }
          };

          fetchDocuments();

        const fetchnotification = async () => {
            try {
              const response = await axios.get(`${BASE_URL}/examnotification`);
              setnotification(response.data.data); // Adjust according to your API response structure
            //   setLoading(false);
            } catch (err) {
              setError(err.message);
              setLoading(false);
            }
          };

          fetchnotification();

        const fetchresult = async () => {
            try {
              const response = await axios.get(`${BASE_URL}/examresult`);
              setresult(response.data.data); // Adjust according to your API response structure
              setLoading(false);
            } catch (err) {
              setError(err.message);
              setLoading(false);
            }
          };
        fetchresult();
    }, []); // Empty dependency array means this effect runs once after initial render

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    function datecheck(isoDate) {
        if (!isoDate) {
            // Handle the case where isoDate is invalid (undefined, null, empty)
            return 'Invalid date';
        }
    
        const date = new Date(isoDate);
        if (isNaN(date.getTime())) {
            // Handle the case where the date is invalid
            return 'Invalid date';
        }
    
        // Extract date in YYYY-MM-DD format
        const formattedDate = date.toISOString().split('T')[0];
        return formattedDate;
    }


    return (
        <>
            <section className="project-detail">
                <div className="container">
                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-12 col-md-12 col-sm-12">
                                <h2>Upsc News: Exam Calendar, Upsc Notification, Upsc Result, FAQs</h2>
                                <div className="upper-box">
                                    <div className="single-item-carousel owl-carousel owl-theme">
                                        <figure className="image d-flex justify-content-center align-items-center">
                                            <img src={img} alt="" />
                                        </figure>
                                    </div>
                                </div>
                                <div className="inner-column">
                                    {/* <div className="course-meta2 review style2 clearfix mb-30">
                                        <ul className="left">
                                            <li>
                                                <div className="author">
                                                    <div className="thumb">
                                                        <img src="assets/img/bg/testi_avatar.jpg" alt="image" />
                                                    </div>
                                                    <div className="text">
                                                        <Link to="/single-courses-2">Robto Jone</Link>
                                                        <p>Teacher</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="categories">
                                                <div className="author">
                                                    <div className="text">
                                                        <a href="#" className="course-name">
                                                            Photoshop
                                                        </a>
                                                        <p>Categories</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <ul className="right">
                                            <li className="price">&#x20b9; 59.00</li>
                                        </ul>
                                    </div> */}
                                    <div >
                                        <h3 id='ExamNews'>Exam Calendar:</h3>
                                        <table className="table table-bordered mb-40">
                                            <thead style={{ backgroundColor: '#FF7350' }}>
                                                <tr style={{ color: 'white', fontSize: '16px' }}>
                                                    <th>Name of Examination</th>
                                                    <th>Duration</th>
                                                    <th>Stating Date</th>
                                                    <th>Ending Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {documents.map((doc) => (
                                                    <tr key={doc.ID}>
                                                        <td>
                                                            <a href={doc.exam_name}>{doc.exam_name}</a>
                                                        </td>
                                                        <td>{doc.duration}</td>
                                                        <td>{datecheck(doc.start_date)}</td>
                                                        <td>{datecheck(doc.end_date)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <h3 id='ExamNotification'>Exam Notification:</h3>
                                    <h4>Introduction:</h4>
                                    <p>Welcome to the General Studies Paper-I section of IAS Passion Institute. This page is dedicated to providing comprehensive resources and guidance for one of the most crucial components of the Civil Services Main Examination.

                                        General Studies Paper-I covers a broad spectrum of subjects that are vital for understanding the socio-cultural and geographical aspects of India and the world. It tests the candidates’ knowledge and understanding of various topics including Indian heritage, culture, history, geography, and society. General Studies Paper-I encompasses a wide range of subjects, including Indian heritage and culture, history, geography, and society, which are essential for a deep understanding of India's socio-cultural and geographical landscape.</p>
                                    <h4>Important Dates:</h4>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                            <tr>
                                                <th>Stage</th>
                                                <th>Event</th>
                                                <th>Tentative Dates(2024)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Notification Release</td>
                                                <td>Exam notification published</td>
                                                <td>{notification && notification[0] ? notification[0].notif_date : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td>Application Period</td>
                                                <td>Online application window open</td>
                                                {/* <td>February 14, March 5, 2024(with correction window)</td> */}
                                                <td>{notification && notification[0] ? notification[0].app_start : 'N/A'} , {notification && notification[0] ? notification[0].app_end : 'N/A'} (with correction window)</td>
                                            </tr>
                                            <tr>
                                                <td>Prelims Exam</td>
                                                <td>Preliminary Examination</td>
                                                <td>{notification && notification[0] ? notification[0].prelim_date : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td>Mains Exam</td>
                                                <td>Mains Examination</td>
                                                {/* <td>September 20 - 27, 2024 (7 days)</td> */}
                                                <td>{notification && notification[0] ? notification[0].mains_date : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td>Interview</td>
                                                <td>Personality Test (Interviews)</td>
                                                {/* <td>To be announced (after Mains results)</td> */}
                                                <td>{notification && notification[0] ? notification[0].interview_date : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td>Result Declaration</td>
                                                <td>Prelims Result</td>
                                                {/* <td>Expected: July/August 2024</td> */}
                                                <td>{notification && notification[0] ? notification[0].result_date : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td>Result Declaration</td>
                                                <td>Mains Result</td>
                                                {/* <td>Expected: October/November 2024</td> */}
                                                <td>{notification && notification[0] ? notification[0].result_date : 'N/A'}</td>
                                            </tr>
                                            <tr>
                                                <td>Final Result</td>
                                                <td>UPSC Final Result (after interviews)</td>
                                                <td>{notification && notification[0] ? notification[0].result_date : 'N/A'}</td>
                                                {/* <td>Expected: December 2024/January 2025</td> */}
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h4>Exam Eligibility Criteria:</h4>

                                    <h6>1. Nationality:</h6>
                                    <ul className='py-2 px-4'>
                                        <li>i. Only <b>Indian citizens</b> are eligible to apply for the UPSC CSE.</li>
                                    </ul>

                                    <h6>2. Age Limit:</h6>
                                    <ul className='py-2 px-4'>
                                        <li>i. The minimum age limit is <b>21 years</b> as of August 1 of the exam year.</li>
                                        <li>ii. The maximum age limit is generally <b>32 years</b> as of August 1 of the exam year.</li>
                                    </ul>


                                    <h6>3. Age Relaxation:</h6>
                                    <ul className='py-2 px-4'>
                                        <li>i. <b>OBC (Other Backward Classes):</b> 3 years relaxation (maximum age limit of 35 years)</li>
                                        <li>ii. <b>SC/ST (Scheduled Castes/Scheduled Tribes):</b> 5 years relaxation (maximum age limit of 37 years)</li>
                                        <li>iii. <b>Persons with Disabilities (PwBD):</b> 10 years relaxation (maximum age limit of 42 years)</li>
                                    </ul>

                                    <h6>4. Educational Qualification:</h6>
                                    <ul className='py-2 px-4'>
                                        <li>i. Universities incorporated by an Act of the Central or State Legislature in India.</li>
                                        <li>ii. Other educational institutions established by an Act of Parliament or declared deemed universities under the University Grants Commission Act, 1956.</li>
                                    </ul>
                                    <h6>5. Number of Attempts:</h6>
                                    <ul className='py-2 px-4'>
                                        <li>i. <b>General:</b> 6 attempts</li>
                                        <li>ii. <b>OBC & PwBD:</b> 9 attempts</li>
                                        <li>iii. <b>SC/ST:</b> No restriction on attempts</li>
                                    </ul>
                                    <h4>Examination Scheme:</h4>
                                    <h6>1. Preliminary Examination (Prelims):</h6>
                                    <ul className='py-2 px-4'>
                                        <li>i. <b>Objective type exam:</b> This is a screening test to shortlist candidates for the Mains exam.</li>
                                        <h6 style={{ padding: "8px" }}>Two Papers:</h6>
                                        <li>
                                            i. <b>General Studies Paper I (GS Paper I):</b> Covers topics like History, Geography, Polity, Indian Economy, Social Issues, Science & Technology, Environment, etc. (200 marks)
                                        </li>
                                        <li>
                                            ii. <b>General Studies Paper II - CSAT (Civil Services Aptitude Test):</b> Assesses logical reasoning, analytical abilities, decision-making, problem-solving, basic numeracy, and comprehension (200 marks)
                                        </li>
                                        <li>
                                            iii. <b>Negative marking:</b> There's negative marking for incorrect answers in both papers.
                                        </li>
                                        <li>
                                            iv. <b>Qualifying nature:</b> Marks obtained in the Prelims are not considered for the final merit list. Only qualifying the minimum cut-off score allows you to appear for the Mains exam.
                                        </li>
                                        <li>
                                            v. <b>Duration:</b> Each paper is 2 hours.
                                        </li>
                                    </ul>
                                    <h6>2. Main Examination (Mains):</h6>
                                    <ul className='py-2 px-4'>
                                        <li>
                                            i. <b>Descriptive type exam:</b> Tests your writing skills, knowledge application, and analytical abilities.
                                        </li>
                                        <h6 style={{ padding: "8px" }}>Seven papers:</h6>
                                        <li>i. <b>Essay Paper (250 marks):</b> Tests your writing style, knowledge, and expression on a diverse range of topics.</li>
                                        <li>ii. <b>Four General Studies Papers (GS Paper II to GS Paper V) (250 marks each):</b> Cover various subjects like Indian and World History, Indian and World Geography, Indian Polity & Governance, Social Justice & Inclusion, Economic Development, Internal Security, Science & Technology in Everyday Life, Disaster Management, and International Relations.</li>
                                        <li>iii. Two Optional Subject Papers (250 marks each): You can choose any two subjects from a wide range offered by UPSC (like Public Administration, History, Geography, Sociology, Literature, etc.).</li>
                                        <li> iv. <b>Elimination nature:</b> Marks obtained in the Mains are considered for the final merit list.</li>
                                        <li> v: <b>Duration:</b> Each paper is 3 hours.</li>
                                    </ul>
                                    <h6>3. Personality Test (Interview):</h6>
                                    <ul className='py-2 px-4'>
                                        <li>
                                            <b>i. Evaluation by a board:</b> A panel of experts assesses your personality, suitability for the civil services, mental alertness, and communication skills.</li>
                                        <li>ii. <b>Qualifying nature:</b> Minimum marks are required to qualify for the final selection.</li>
                                        <li>iii. <b>Marks weightage:</b> Interview marks hold 275 marks in the final merit list.</li>

                                    </ul>
                                    <h4>Exam Syllabus:</h4>
                                    <h6>1. General Studies Paper I (GS Paper I):</h6>
                                    <ul className='py-2 px-4'>
                                        <li>i. <b>Current events of national and international importance:</b> Stay updated on current affairs, national policies, international relations, and significant global occurrences.</li>
                                        <li>ii. <b>History of India and Indian National Movement:</b> Study the history of India from the ancient period to the post-independence era, including the Indian National Movement.</li>
                                        <li>iii. <b>Indian and World Geography - Physical, Social, Economic Geography of India and the World:</b> Gain a strong understanding of physical geography (landforms, climate), social geography (demographics, settlements), and economic geography (resources, industries, trade) of India and the world.</li>
                                        <li>iv. <b>Indian Polity and Governance - Constitution, Political System, Panchayati Raj, Public Policy, Rights Issues, etc.:</b> Be thorough with the Indian Constitution, political system (Parliament, Judiciary, Executive), Panchayati Raj, public policy formulation and implementation, and fundamental rights.</li>
                                        <li>v. <b>Economic and Social Development - Sustainable Development, Poverty, Inclusion, Demographics, Social Sector initiatives, etc.:</b> Understand economic development theories, poverty alleviation strategies, social inclusion programs, demographic trends, and social sector initiatives.</li>
                                        <li>vi. <b>General issues on Environmental Ecology, Biodiversity, and Climate Change - that do not require subject specialisation:</b> Develop a basic understanding of environmental issues, ecological concepts, biodiversity conservation, and climate change.</li>
                                        <li>vii. <b>General Science:</b> This section might cover basic scientific concepts in Physics, Chemistry, Biology, and everyday science applications.</li>
                                    </ul>
                                    <h6>General Studies Paper II - CSAT (Civil Services Aptitude Test)</h6>
                                    <ul className='py-2 px-4'>
                                        <li>i. <b>Comprehension:</b> Ability to understand and analyze factual passages, draw inferences, and summarize information.</li>
                                        <li>ii. <b>Interpersonal skills including communication skills:</b> Evaluate your communication effectiveness, interpersonal skills, and ability to work in a team.</li>
                                        <li>iii. <b>Logical reasoning and analytical ability:</b> Assess your ability to identify patterns, analyze situations logically, and solve problems critically.</li>
                                        <li>iv. <b>Decision-making and problem-solving:</b> Evaluate your decision-making skills in various situations and problem-solving approaches.</li>
                                        <li>v. <b>General mental ability:</b> Test your basic reasoning, numerical ability, data interpretation, and spatial reasoning skills.</li>
                                    </ul>
                                    <h6>3. Main Examination (Mains):</h6>
                                    <ul className='py-2 px-4'>
                                        <h6>Seven papers are offered in the Mains exam:</h6>
                                        <li>i. <b>Essay Paper (250 marks):</b> This paper assesses your writing skills, ability to express ideas coherently, and knowledge on diverse topics.</li>
                                        <h6><b>Four General Studies Papers (GS Paper II to GS Paper V) (250 marks each):</b> These papers cover a wide range of subjects:</h6>
                                        <li>i. <b>GS Paper II - Governance, Constitution, Polity, Social Justice and International relations:</b> Delve deeper into Indian Polity, governance, social justice issues, and international relations.</li>
                                        <li>ii. <b>GS Paper III - Technology, Economic Development, Bio diversity, Environment, Security and Disaster Management:</b> Focus on Science & Technology, economic development models, environmental issues, biodiversity, national security concerns, and disaster management.</li>
                                        <li>iii. <b>GS Paper IV - Ethics, Integrity and Aptitude:</b> Analyze ethical dilemmas, understand the importance of integrity in civil service, and assess your suitability for the service.</li>
                                        <li>iv. <b>GS Paper V - Optional Subject Paper I and Optional Subject Paper II (250 marks each):</b> You can choose any two subjects from a vast list offered like Public Administration, History, Geography, Sociology, Literature, etc. Each optional subject has a separate syllabus provided by UPSC.</li>
                                    </ul>
                                    <h6>Exam Application Process:</h6>
                                    <ul className='py-2 px-4'>
                                        <h6>1. Registration</h6>
                                        <li>i. Visit the official UPSC website: <a href='https://upsconline.nic.in'>https://upsconline.nic.in</a></li>
                                        <li>ii. Click on the "Online Recruitment Application" link for the UPSC CSE exam.</li>
                                        <li>iii. Register by providing your basic details like name, date of birth, email address, and mobile number.</li>
                                        <li>iv. You'll receive a Registration ID (RID) and password for future login.</li>
                                    </ul>
                                    <ul className='py-2 px-4'>
                                        <h6>2. <b>Part I - Filling the Online Application Form (OAF):</b></h6>
                                        <li>i. Login using your RID and password.</li>
                                        <li>ii. Fill in the online application form carefully and accurately.</li>
                                        <h6>Provide details Like:</h6>
                                        <li>i. Personal information (name, father's name, mother's name, etc.)</li>
                                        <li>ii. Educational qualifications (degrees, marksheets, universities)</li>
                                        <li>iii. Category (General, OBC, SC, ST, etc.)</li>
                                        <li>iv. Communication address and permanent address</li>
                                        <li>v. Optional Subjects chosen (if applicable)</li>
                                        <li>vi. Examination Center preferences</li>
                                    </ul>
                                    <ul className='py-2 px-4'>
                                        <h6>3. Part II - Uploading Documents and Fee Payment:</h6>
                                        <li>Upload scanned copies of required documents in the specified format and size. Documents might include:</li>
                                        <li>i. Photograph</li>
                                        <li>ii. Signature</li>
                                        <li>iii. Category certificate (if applicable)</li>
                                        <li>iv. Educational certificates (mark sheets, degrees)</li>
                                        <li>v. Photo ID proof</li>
                                    </ul>
                                    <ul className='py-2 px-4'>
                                        <h6>4. Verification and Submission:</h6>
                                        <li>i. Review your application form thoroughly for any errors or omissions.</li>
                                        <li>ii. Once satisfied, submit the final application form.</li>
                                        <li>iii. Take a printout of the submitted application for your records.</li>
                                    </ul>
                                    <h6>Exam Important Instructions:</h6>
                                    <ul className='py-2 px-4'>
                                        <h6>During Application</h6>
                                        <li>i. <b>Accuracy and Completeness:</b> Ensure all information entered in the online application form is accurate and complete. Mistakes or omissions can lead to disqualification.</li>
                                        <li>ii. <b>Eligibility Verification:</b> Carefully review the eligibility criteria mentioned in the UPSC notification. Ensure you meet all the requirements before applying.</li>
                                        <li>iii. <b>Document Uploads:</b> Upload scanned copies of required documents in the specified format and size. Failure to upload necessary documents might result in application rejection.</li>
                                        <li>iv. <b>Fee Payment:</b> Pay the application fee online within the stipulated deadline using the prescribed mode. Late fee payments might not be accepted.</li>
                                        <li>v. <b>Application Submission:</b> Review your application form carefully before final submission. Once submitted, changes are usually not allowed.</li>
                                    </ul>

                                    <ul className='py-2 px-4'>
                                        <h6>During Examination:</h6>
                                        <li>i. <b>Admit Card:</b> Download and print your admit card (e-Admit Card) from the UPSC website before the exam. Carry a valid photo ID proof along with the admit card to the exam hall.</li>
                                        <li>ii. <b>Exam Center:</b> Report to the exam center mentioned on your admit card well before the exam time. Latecomers might not be allowed to enter the exam hall.</li>
                                        <li>iii. <b>Exam Rules and Regulations:</b> Familiarize yourself with the exam rules and regulations mentioned in the notification. These include restrictions on prohibited items (mobile phones, electronic devices, etc.), conduct during the exam, and disciplinary actions for violations.</li>
                                        <li>iv. <b>Stationery:</b> Bring the necessary stationery items like pens, pencils, eraser, and a sharpener as specified in the notification.</li>
                                        <li>v. <b>Fair Means:</b> Practice ethical behavior and avoid any unfair means (copying, malpractice) during the exam. Strict action is taken against candidates found indulging in such practices.</li>
                                    </ul>

                                    <ul className='py-2 px-4'>
                                        <h6>Selection Process:</h6>
                                        <li>i. <b>Interview:</b> If shortlisted for the interview, appear at the designated UPSC center on the scheduled date and time. Dress formally and present yourself professionally. Be prepared to answer questions related to your background, knowledge, and suitability for the civil services.</li>
                                        <li>ii. <b>Communication:</b> Maintain open communication with the UPSC throughout the selection process. Respond promptly to any communication received from the commission.</li>
                                        <li>iii. <b>Medical Examination:</b> If recommended for appointment, you might be required to undergo a medical examination by authorized doctors.</li>
                                    </ul>


                                    <h4 id='ExamResult'>Exam Results:</h4>
                                    <ul className='py-2 px-4'>
                                        <h6>1. Prelims Result:</h6>
                                        <li>i. Released approximately 6-8 weeks after the Prelims exam.</li>
                                        <li>ii. UPSC publishes a list with the roll numbers of candidates who qualify for the Mains exam</li>
                                        <li>iii. The list doesn't include marks obtained by individual candidates.</li>
                                        <li>iv. You can access the result on the UPSC website:<a href='https://upsc.gov.in/'></a>https://upsc.gov.in/</li>
                                    </ul>
                                    <ul className='py-2 px-4'>
                                        <h6>2. Mains Result:</h6>
                                        <li>i. Declared approximately 3-4 months after the Mains exam.</li>
                                        <li>ii. UPSC publishes a list with the roll numbers of candidates who qualify for the interview stage.</li>
                                        <li>iii. Similar to the Prelims result, individual marks are not revealed.</li>
                                        <li>iv. The result can be accessed on the UPSC website.</li>
                                    </ul>
                                    <ul className='py-2 px-4'>
                                        <h6>3. Final Result:</h6>
                                        <li>i. Announced approximately 2-3 months after the interview stage.</li>
                                        <li>ii. This is the most crucial result, as it determines the final selection of candidates for various civil services.</li>
                                        <li>iii. UPSC publishes a final merit list with the name, roll number, and ranks of all recommended candidates.</li>
                                        <li>iv. The list also specifies the service (IAS, IPS, etc.) for which each candidate is recommended.</li>
                                    </ul>
                                    <table className="table table-bordered mb-40">
                                            <thead style={{ backgroundColor: '#FF7350' }}>
                                                <tr style={{ color: 'white', fontSize: '16px' }}>
                                                    <th>Name of Examination</th>
                                                    <th>Result Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {result.map((doc) => (
                                                    <tr key={doc.ID}>
                                                        <td>
                                                            <a href={doc.exam_name}>{doc.exam_name}</a>
                                                        </td>
                                                        <td>{datecheck(doc.result_date)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    <h3 id='FAQs'>Frequently Asked Question</h3>
                                    <p>
                                        The UPSC Civil Services Exam (CSE) is a dream for many, and institutes like IAS Passion aim to help aspirants turn that dream into reality. Here are some commonly asked questions to shed light on what IAS Passion offers..
                                    </p>
                                    <div className="faq-wrap pt-30 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                                        <div className="accordion" id="accordionExample">
                                            {faqItems.map((item, index) => (
                                                <div className="card" key={index}>
                                                    <div className="card-header" id={item.id}>
                                                        <h2 className="mb-0">
                                                            <button className={`faq-btn${activeIndex === index ? '' : ' collapsed'}`} type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={`#collapse${index}`}
                                                                onClick={() => handleToggle(index)}>
                                                                {item.question}
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id={`collapse${index}`}
                                                        className={`collapse${activeIndex === index ? ' show' : ''}`}
                                                        data-bs-parent="#accordionExample" >
                                                        <div className="card-body">{item.answer}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FirstNew