import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import pdf1 from '../../assets/img/calendar/Annual_Calender2025.pdf';
import pdf2 from '../../assets/img/calendar/Revised_Calender2024.pdf';
import pdf3 from '../../assets/img/calendar/AnnualCalendar-2024.pdf';
import img from '../../assets/img/bg/ias15.png';
import axios from 'axios';
import { BASE_URL } from '../../iaspassionConfig';

const faqItems = [
    {
        id: 'headingThree',
        question: 'What does IAS Passion Institute offer?',
        answer: 'The institute likely offers coaching programs for the UPSC Civil Services Examination (CSE), with a focus on General Studies Paper-I and other GS papers. They might also provide guidance on optional subjects, essay writing, and interview skills.',
    },
    {
        id: 'headingOne',
        question: 'Is IAS Passion Institute good for IAS preparation?',
        answer: 'The effectiveness of any coaching institute depends on individual learning styles and preferences. Consider factors like faculty experience, study materials, success rates (if available), and student reviews before making a decision.',
    },
    {
        id: 'headingTwo',
        question: 'What are the fees of IAS Passion Institute?',
        answer: 'Fee structures can vary depending on the program duration and type. Contact the institute directly for their latest fee information.',
    },
];

function FirstIAS() {

    const [activeIndex, setActiveIndex] = useState(null);
    const [book, setbook] = useState([]);
    const [plan, setplan] = useState([]);

    const handleToggle = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchbook = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/exambook`);
                setbook(response.data.data); // Adjust according to your API response structure
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchbook();

        const fetchPlan = async () => {
            try {
              const response = await axios.get(`${BASE_URL}/examplan`);
              setplan(response.data.data); // Adjust according to your API response structure
            //   setLoading(false);
            } catch (err) {
              setError(err.message);
              setLoading(false);
            }
          };

          fetchPlan();

        // const fetchnotification = async () => {
        //     try {
        //       const response = await axios.get('http://localhost:8000/examnotification');
        //       setnotification(response.data.data); // Adjust according to your API response structure
        //     //   setLoading(false);
        //     } catch (err) {
        //       setError(err.message);
        //       setLoading(false);
        //     }
        //   };

        //   fetchnotification();

        // const fetchresult = async () => {
        //     try {
        //       const response = await axios.get('http://localhost:8000/examresult');
        //       setresult(response.data.data); // Adjust according to your API response structure
        //       setLoading(false);
        //     } catch (err) {
        //       setError(err.message);
        //       setLoading(false);
        //     }
        //   };
        // fetchresult();
    }, []); // Empty dependency array means this effect runs once after initial render

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    function datecheck(isoDate) {
        if (!isoDate) {
            // Handle the case where isoDate is invalid (undefined, null, empty)
            return 'Invalid date';
        }

        const date = new Date(isoDate);
        if (isNaN(date.getTime())) {
            // Handle the case where the date is invalid
            return 'Invalid date';
        }

        // Extract date in YYYY-MM-DD format
        const formattedDate = date.toISOString().split('T')[0];
        return formattedDate;
    }

    return (
        <>
            <section className="project-detail">
                <div className="container">
                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-12 col-md-12 col-sm-12">
                                <h2>IAS Exam: CSE Exam Plan, Syllabus, Books, Previous Year Question Paper</h2>
                                <div className="upper-box">
                                    <div className="single-item-carousel owl-carousel owl-theme">
                                        <figure className="image">
                                            <img src={img} alt="" />
                                        </figure>
                                    </div>
                                </div>
                                <div className="inner-column">
                                    {/* <div className="course-meta2 review style2 clearfix mb-30">
                                        <ul className="left">
                                            <li>
                                                <div className="author">
                                                    <div className="thumb">
                                                        <img src="assets/img/bg/testi_avatar.jpg" alt="image" />
                                                    </div>
                                                    <div className="text">
                                                        <Link to="/single-courses-2">Robto Jone</Link>
                                                        <p>Teacher</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="categories">
                                                <div className="author">
                                                    <div className="text">
                                                        <a href="#" className="course-name">
                                                            Photoshop
                                                        </a>
                                                        <p>Categories</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <ul className="right">
                                            <li className="price">&#x20b9; 59.00</li>
                                        </ul>
                                    </div> */}
                                    <div>
                                        <h3 id='cseexam'>CSE Exam Plan - Introduction</h3>
                                        <p style={{ fontSize: "1.1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax" }}>
                                            Opening Statement: Briefly introduce the Civil Services Examination (CSE) and its significance in India.
                                            Importance of a Study Plan: Explain why having a structured study plan is crucial for success in the CSE.
                                        </p>
                                        <h3>Understanding the CSE</h3>
                                        <h4>What is the CSE?</h4>
                                        <p style={{ fontSize: "1.1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax" }}>
                                            Provide an overview of the CSE, conducted by the Union Public Service Commission (UPSC), including the roles it recruits for, such as IAS, IPS, IFS, etc.
                                        </p>
                                        <h4>Stages of the CSE:</h4>
                                            <h5>1.Preliminary Examination (Prelims)</h5>
                                            <h5>2.Main Examination (Mains)</h5>
                                            <h5>3.Personality Test (Interview)</h5>
                                            <h3 className='py-2'>Stage-wise Study Plan</h3>
                                            <h5>Structure:</h5>
                                            <p className='my-0' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>Type of Questions</b>: Multiple Choice Questions (MCQs)
                                             </p>
                                            <p className='my-0' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>Total Marks</b>: 200
                                             </p>
                                            <p className='my-0' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>Number of Questions</b>: 100
                                             </p>
                                            <p className='my-0' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>Duration</b>: {plan && plan[0] ? plan[0].duration : 'N/A'} hours
                                             </p>
                                            <p className='my-0' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>Negative Marking</b>: 1/3rd of the marks assigned to a question for every wrong answer.
                                             </p>
                                            <p className='my-0' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>Exam Center</b>: {plan && plan[0] ? plan[0].exam_centers : 'N/A'}
                                             </p>
                                            <p className='my-0' style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>Mode</b>: {plan && plan[0] ? plan[0].exam_mode : 'N/A'}
                                             </p>
                                            <p style={{ fontSize: "1.1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>
                                            <b>Total Vacancies</b>: {plan && plan[0] ? plan[0].total_vacancies : 'N/A'}
                                             </p>
                                             <h4>Preparation Strategy:</h4>
                                             
                                        <h4>Stage 1: Preliminary Examination (Prelims):</h4>
                                   <ul className='py-2 px-4'>
                                    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>i. <b>12-18 Months Before Exam:</b> Focus on building a strong foundation in General Studies subjects like History, Geography, Polity, Economy, Science & Technology, and Environment. NCERT textbooks are a great starting point.</li>
                                    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>ii. <b>6-12 Months Before Exam:</b> Start practicing previous years' question papers for both GS Paper I and CSAT. Analyze question patterns, identify your strengths and weaknesses, and work on areas that need improvement.</li>
                                    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>iii. <b>3-6 Months Before Exam:</b> Intensify your practice with mock tests offered by coaching institutes or online platforms. This will help you develop time management skills and get comfortable with the exam format.</li>
                                    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>iv. <b>Last 1-3 Months Before Exam:</b> Revise key concepts, focus on current affairs, and practice answer writing for essay writing and precis writing (CSAT).</li>
                                   </ul>
                                   <h4>Stage 2: Main Examination (Mains):</h4>
                                   <ul className='py-2 px-4'>
                                    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>i. <b>After Prelims Result (Assuming you qualify):</b> Start strategizing for the Mains exam. Choose your Optional Subjects wisely based on your interest and aptitude.</li>
                                    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>ii. <b>6-12 Months Before Mains:</b> Focus on in-depth study of General Studies subjects using standard reference books and subject-specific materials. Start preparing for your Optional Subjects.</li>
                                    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>iii. <b>3-6 Months Before Mains:</b> Intensify your answer writing practice for all Mains papers (Essay, GS, and Optional). Practice answer structuring, content organization, and effective writing style.</li>
                                    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>iv. <b>Last 1-3 Months Before Mains:</b> Focus on revision, current affairs updates, and mock tests specifically designed for the Mains exam format.</li>
                                   </ul>
                                   <h4>Stage 3: Personality Test (Interview):</h4>
                                   <ul className='py-2 px-4'>
                                    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>i. <b>Throughout Preparation:</b> Practice clear and concise communication, hone your analytical and problem-solving skills, and stay updated on current events.</li>
                                    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>ii. <b>After Mains Result (Assuming you qualify):</b> Start preparing for the interview by working on self-introductions, mock interviews, and current affairs discussions.</li>
                                    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>iii. <b>1-2 Months Before Interview:</b> Focus on in-depth analysis of your DAF (Detailed Application Form) and be prepared to answer questions related to your background, achievements, and motivations for joining the civil services.</li>
                                   </ul>
                                    </div>
                                    <h4 id='syllabus'>IAS Syllabus:</h4>
<h6>1. General Studies Paper I (GS Paper I):</h6>
<ul className='py-2 px-4'>
    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>i. <b>Current events of national and international importance:</b> Stay updated on current affairs, national policies, international relations, and significant global occurrences.</li>
    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>ii. <b>History of India and Indian National Movement:</b> Study the history of India from the ancient period to the post-independence era, including the Indian National Movement.</li>
    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>iii. <b>Indian and World Geography - Physical, Social, Economic Geography of India and the World:</b> Gain a strong understanding of physical geography (landforms, climate), social geography (demographics, settlements), and economic geography (resources, industries, trade) of India and the world.</li>
    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>iv. <b>Indian Polity and Governance - Constitution, Political System, Panchayati Raj, Public Policy, Rights Issues, etc.:</b> Be thorough with the Indian Constitution, political system (Parliament, Judiciary, Executive), Panchayati Raj, public policy formulation and implementation, and fundamental rights.</li>
    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>v. <b>Economic and Social Development - Sustainable Development, Poverty, Inclusion, Demographics, Social Sector initiatives, etc.:</b> Understand economic development theories, poverty alleviation strategies, social inclusion programs, demographic trends, and social sector initiatives.</li>
    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>vi. <b>General issues on Environmental Ecology, Biodiversity, and Climate Change - that do not require subject specialisation:</b> Develop a basic understanding of environmental issues, ecological concepts, biodiversity conservation, and climate change.</li>
    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>vii. <b>General Science:</b> This section might cover basic scientific concepts in Physics, Chemistry, Biology, and everyday science applications.</li>
</ul>
<h6>2. General Studies Paper II - CSAT (Civil Services Aptitude Test)</h6>
<ul className='py-2 px-4'>
    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>i. <b>Comprehension:</b> Ability to understand and analyze factual passages, draw inferences, and summarize information.</li>
    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>ii. <b>Interpersonal skills including communication skills:</b> Evaluate your communication effectiveness, interpersonal skills, and ability to work in a team.</li>
    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>iii. <b>Logical reasoning and analytical ability:</b> Assess your ability to identify patterns, analyze situations logically, and solve problems critically.</li>
    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>iv. <b>Decision-making and problem-solving:</b> Evaluate your decision-making skills in various situations and problem-solving approaches.</li>
    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>v. <b>General mental ability:</b> Test your basic reasoning, numerical ability, data interpretation, and spatial reasoning skills.</li>
</ul>
<h6>3. Main Examination (Mains):</h6>
<ul className='py-2 px-4'>
    <h6>Seven papers are offered in the Mains exam:</h6>
    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>i. <b>Essay Paper (250 marks):</b> This paper assesses your writing skills, ability to express ideas coherently, and knowledge on diverse topics.</li>
    <h6 style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}><b>Four General Studies Papers (GS Paper II to GS Paper V) (250 marks each):</b> These papers cover a wide range of subjects:</h6>
    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>i. <b>GS Paper II - Governance, Constitution, Polity, Social Justice and International relations:</b> Delve deeper into Indian Polity, governance, social justice issues, and international relations.</li>
    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>ii. <b>GS Paper III - Technology, Economic Development, Bio diversity, Environment, Security and Disaster Management:</b> Focus on Science & Technology, economic development models, environmental issues, biodiversity, national security concerns, and disaster management.</li>
    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>iii. <b>GS Paper IV - Ethics, Integrity and Aptitude:</b> Analyze ethical dilemmas, understand the importance of integrity in civil service, and assess your suitability for the service.</li>
    <li style={{ fontSize: "1vmax", wordSpacing: "0.1vmax", letterSpacing: "0.1vmax", color: "black" }}>iv. <b>GS Paper V - Optional Subject Paper I and Optional Subject Paper II (250 marks each):</b> You can choose any two subjects from a vast list offered like Public Administration, History, Geography, Sociology, Literature, etc. Each optional subject has a separate syllabus provided by UPSC.</li>
</ul>
                                    <h4 id='books'>Books :</h4>
                                    <table className="table table-bordered mb-40">
                                        <thead style={{ backgroundColor: '#FF7350' }}>
                                            <tr style={{ color: 'white', fontSize: '16px' }}>
                                                <th>Name of Book</th>
                                                <th>Name of Publication</th>
                                                <th>Price</th>
                                                <th>Upload Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {book.map((doc) => (
                                                <tr key={doc.ID}>
                                                    <td>
                                                        <a href={doc.book_name}>{doc.book_name}</a>
                                                    </td>
                                                    <td>
                                                        {doc.publication}
                                                    </td>
                                                    <td>{doc.price}</td>
                                                    <td>{datecheck(doc.updated_at)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FirstIAS