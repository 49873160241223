import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../iaspassionConfig';

// const faqItems = [
//     {
//         question: 'What does IAS Passion Institute offer?',
//         answer: 'The institute likely offers coaching programs for the UPSC Civil Services Examination (CSE), with a focus on General Studies Paper-I and other GS papers. They might also provide guidance on optional subjects, essay writing, and interview skills.',
//     },
//     {
//         question: 'Is IAS Passion Institute good for IAS preparation?',
//         answer: 'The effectiveness of any coaching institute depends on individual learning styles and preferences. Consider factors like faculty experience, study materials, success rates (if available), and student reviews before making a decision.',
//     },
//     {
//         question: 'What are the fees of IAS Passion Institute?',
//         answer: 'Fee structures can vary depending on the program duration and type. Contact the institute directly for their latest fee information.',
//     },
//     {
//         question: 'What are the different optional subjects offered in the UPSC CSE Mains exam?',
//         answer: 'The UPSC offers a wide range of optional subjects for the Mains exam, covering various disciplines like History, Public Administration, Sociology, Geography, Literature, and many more. You can find the complete list on the UPSC website.',
//     },
//     {
//         question: 'What is the ideal timeline for preparing for the UPSC CSE exam?',
//         answer: 'The ideal timeline for preparation can vary depending on your individual background and learning pace. However, most aspirants dedicate 1-2 years of focused preparation.',
//     },
// ];

function Frequently() {

    const [activeIndex, setActiveIndex] = useState(null);
    const [faqItems, setFAQItems] = useState([])
    const handleToggle = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const fetchFaqs = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/faq`);
          setFAQItems(response.data.data); // Adjust according to your API response structure
          setLoading(false);
        } catch (err) {
          setError(err.message);
          setLoading(false);
        }
      };
  
      fetchFaqs();
    }, []); // Empty dependency array means this effect runs once after initial render
  
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <>
            <section className="faq-area pt-120 pb-120 p-relative fix">
                <div className="animations-10">
                    <img src="assets/img/bg/an-img-04.png" alt="an-img-01" />
                </div>
                <div className="animations-08">
                    <img src="assets/img/bg/an-img-05.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center  align-items-center">
                        <div className="col-lg-7">
                            <div className="section-title wow fadeInLeft animated" data-animation="fadeInDown animated" data-delay=".2s">
                                <h2>Get every single answer here.</h2>
                                <p>
                                Here are some frequently asked questions (FAQs) relevant to a website like IASpassion that can be categorized into different stages of the UPSC Civil Services Exam (CSE) preparation journey:
                                </p>
                            </div>
                            <div className="faq-wrap mt-30 pr-30">
                                <div className="accordion" id="accordionExample">
                                    {faqItems.map((item, index) => (
                                        <div className="card" key={index}>
                                            <div className="card-header" id={`heading${index}`}>
                                                <h2 className="mb-0">
                                                    <button className={`faq-btn${activeIndex === index ? '' : ' collapsed'}`}
                                                        type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} onClick={() => handleToggle(index)} >
                                                        {item.question}
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id={`collapse${index}`} className={`collapse${activeIndex === index ? ' show' : ''}`} data-bs-parent="#accordionExample" >
                                                <div className="card-body">{item.answer}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="contact-bg02">
                                <div className="section-title wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s">
                                    <h2>Make An Contact</h2>
                                </div>
                                <form action="mail.php" method="post" className="contact-form mt-30 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-name mb-20">
                                                <input type="text" id="firstn" name="firstn" placeholder="First Name" required="" />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-subject mb-20">
                                                <input type="text" id="email" name="email" placeholder="Email" required="" />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-subject mb-20">
                                                <input type="text" id="phone" name="phone" placeholder="Phone No." required="" />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-message mb-30">
                                                <textarea name="message" id="message" cols={30} rows={10} placeholder="Write comments" defaultValue={""} />
                                            </div>
                                            <div className="slider-btn">
                                                <button className="btn ss-btn" data-animation="fadeInRight" data-delay=".8s" >
                                                    <span>Submit Now</span>{" "}
                                                    <i className="fal fa-long-arrow-right" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Frequently