import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { MdKeyboardArrowLeft } from "react-icons/md";
// import { TbArrowBadgeLeftFilled } from "react-icons/tb";
import { RiArrowDownSLine } from "react-icons/ri";
import logoimg from '../../assets/img/logo/iaspassionlogo.png';

function Main() {

    const [Btnshow, setBtnshow] = useState(false);
    const [mobile, setmobile] = useState(false);
    const [Events, setEvent] = useState(false);
    const [News, setNews] = useState(false);
    const [Services, setServices] = useState(false);
    const [Blog, setblog] = useState(false);
    const [toggleopen1, settoggleopen1] = useState(false);
    const [toggleopen2, settoggleopen2] = useState(false);
    const [toggleopen3, settoggleopen3] = useState(false);
    const [toggleopen4, settoggleopen4] = useState(false);
    const [toggleopen5, settoggleopen5] = useState(false);
    const [toggleopen6, settoggleopen6] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            setIsScrolled(scrollTop > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handlestorytoggle = () => {
        settoggleopen1(!toggleopen1)
    }

    const handlestorytoggle1 = () => {
        settoggleopen2(!toggleopen2)
        settoggleopen3(false)
        settoggleopen4(false)
        settoggleopen5(false)
        settoggleopen6(false)
    }

    const handlestorytoggle2 = () => {
        settoggleopen3(!toggleopen3)
        settoggleopen2(false)
        settoggleopen4(false)
        settoggleopen5(false)
        settoggleopen6(false)
    }

    const handlestorytoggle3 = () => {
        settoggleopen4(!toggleopen4)
        settoggleopen3(false)
        settoggleopen2(false)
        settoggleopen5(false)
        settoggleopen6(false)
    }

    const handlestorytoggle4 = () => {
        settoggleopen5(!toggleopen5)
        settoggleopen3(false)
        settoggleopen4(false)
        settoggleopen2(false)
        settoggleopen6(false)
    }

    const handlestorytoggle5 = () => {
        settoggleopen6(!toggleopen6)
        settoggleopen3(false)
        settoggleopen4(false)
        settoggleopen5(false)
        settoggleopen2(false)
    }


    return (
        <>
            <header className="header-area header-three">
                <div className="header-top second-header d-none d-md-block">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-4 d-none d-lg-block ">
                                <div className="header-social">
                                    <span>
                                        Follow us:-
                                        <Link to="#" title="Facebook">
                                            <i className="fab fa-facebook-f" />
                                        </Link>
                                        <Link to="#" title="LinkedIn">
                                            <i className="fab fa-instagram" />
                                        </Link>
                                        <Link to="#" title="Twitter">
                                            <i className="fab fa-twitter" />
                                        </Link>
                                        <Link to="#" title="Twitter">
                                            <i className="fab fa-youtube" />
                                        </Link>
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-8 d-none d-lg-block text-right">
                                <div className="header-cta">
                                    <ul>
                                        <li>
                                            <div className="call-box">
                                                <div className="icon">
                                                    <img src="assets/img/icon/phone-call.png" alt="img" />
                                                </div>
                                                <div className="text">
                                                    <span>Call Now !</span>
                                                    <strong>
                                                        <Link to="tel:+917052101786">+91 7052 101 786</Link>
                                                    </strong>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="call-box">
                                                <div className="icon">
                                                    <img src="assets/img/icon/mailing.png" alt="img" />
                                                </div>
                                                <div className="text">
                                                    <span>Email Now</span>
                                                    <strong>
                                                        <Link to="mailto:info@example.com"> info@iaspassion.com</Link>
                                                    </strong>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="header-sticky" className={`menu-area${isScrolled ? 'menu-area sticky-menu' : ''}`}>
                    <div className="container">
                        <div className="second-menu">
                            <div className="row align-items-center">
                                <div className="col-xl-3 col-lg-3">
                                    <div className="logo">
                                        <Link to="/">
                                            <img src={logoimg} alt="logo" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="main-menu text-right text-xl-right">
                                        <nav id="mobile-menu">
                                            <ul>
                                                <li>
                                                    <Link to="/">Home</Link>
                                                </li>
                                                <li className="has-sub">
                                                    <Link to="/">Upsc News</Link>
                                                    <ul>
                                                        <li>
                                                            <a href='/ExamNews#ExamNews'>Exam Calendar</a>
                                                        </li>
                                                        <li>
                                                            <a href="#ExamNotification">UPSC Notification</a>
                                                        </li>
                                                        <li>
                                                            <a href="#ExamResult">UPSC Result</a>
                                                        </li>
                                                        <li>
                                                            <a href="#FAQs">FAQs</a>
                                                        </li>
                                                    </ul>
                                                </li>

                                                <li className="has-sub">
                                                    <Link to="#">IAS Exam</Link>
                                                    <ul>
                                                        <li>
                                                            <a href="/IAS#cseexam">CSE Exam Plan</a>
                                                        </li>
                                                        <li>
                                                            {" "}
                                                            <a href="#syllabus">Syllabus</a>
                                                        </li>
                                                        <li>
                                                            {" "}
                                                            <a href="#books">Books</a>
                                                        </li>
                                                        {/* <li>
                                                            {" "}
                                                            <Link to="/single-courses-2">Previous Year Question Paper</Link>
                                                        </li> */}
                                                    </ul>
                                                </li>
                                                <li className="has-sub">
                                                    <Link to="#">How To Prepare</Link>
                                                    <ul>
                                                        <li>
                                                            <a href="/PREPARE#prelims">Preliminary Examination</a>
                                                        </li>
                                                        <li>
                                                            <a href="#mains">Main Examination</a>
                                                        </li>
                                                        <li>
                                                            <a href="#interview">Interview</a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="has-sub">
                                                    <Link to="#">Analysis & Interpretation</Link>
                                                    <ul>
                                                        <li>
                                                            <Link to="/analysis">Cut-Off Marks</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">Exam Statistics</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">Question Papers Analysis</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">Success In First Attempt</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="#">Consistent Campaigner</Link>
                                                        </li>
                                                        <li className="has-sub">
                                                            <Link to="#" className='d-flex justify-content-between align-items-center'>IAS Success Story
                                                                <MdKeyboardArrowLeft /></Link>
                                                            <ul>
                                                                <li>
                                                                    <Link to="#" className='d-flex justify-content-between align-items-center' onClick={() => handlestorytoggle()}>Analysis and Evaluation <RiArrowDownSLine /></Link>
                                                                </li>
                                                                {toggleopen1 && <><li>
                                                                    <Link to="/SUCCESSSTORY">Daily MCQs/Trivia</Link>
                                                                </li>
                                                                    <li>
                                                                        <Link to="/blog-details">Previous Years Questions</Link>
                                                                    </li></>}
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <Link to="/contact">Toppers Comer</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/contact">News Views & Features</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/contact">Videos</Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                                {/* <li className="has-sub">
                                                    <Link to="#">IAS Success Story</Link>
                                                    <ul>
                                                        <li>
                                                            <a href="/SUCCESSSTORY#Success">Analysis and Evaluation</a>
                                                        </li>
                                                        <li>
                                                            <a href="#daily">Daily MCQs/Trivia</a>
                                                        </li>
                                                        <li>
                                                            <Link to="/blog-details">Previous Years Questions</Link>
                                                        </li>
                                                    </ul>
                                                </li> */}
                                                {/* <li>
                                                    <Link to="/contact">Toppers Comer</Link>
                                                </li>
                                                <li>
                                                    <Link to="/contact">News Views & Features</Link>
                                                </li>
                                                <li>
                                                    <Link to="/contact">Videos</Link>
                                                </li> */}
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-3 text-right d-none d-lg-block text-right text-xl-right">
                                    <div className="login">
                                        <ul>
                                            <li>
                                                <Link to="#" className="menu-tigger" onClick={() => { setBtnshow(true) }}>
                                                    <i className="fal fa-search" onClick={() => { setBtnshow(true) }} />
                                                </Link>
                                            </li>
                                            <li>
                                                <div className="second-header-btn">
                                                    <Link to="/contact" className="btn">Resigter Now</Link>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mobile-menu mean-container">
                                        <div className="mean-bar">
                                            <a href="#nav" onClick={() => setmobile(!mobile)} className={`meanmenu-reveal ${mobile && "meanclose"}`} style={{ right: 0, left: "auto", textAlign: "center", textIndent: 0, fontSize: 18 }} >
                                                {mobile ? "X" : <span><span><span></span></span></span>}
                                            </a>
                                            {mobile &&
                                                <nav className="mean-nav">
                                                    <ul style={{ display: "block" }}>
                                                        <li className="has-sub">
                                                            <a href="/">Home</a>
                                                            {/* {Events &&
                                                                <ul style={{ display: "block" }}>
                                                                    <li>
                                                                        <Link to="/">University</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="/home-two">Kindergarten</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="/home-three">High School</Link>
                                                                    </li>
                                                                </ul>
                                                            }
                                                            <a className={`mean-expand ${mobile && "mean-clicked"}`} onClick={() => { setEvent(!Events) }} href="#" style={{ fontSize: 18 }}>
                                                                {Events ? "-" : "+"}
                                                            </a> */}
                                                        </li>
                                                        <li>
                                                            <Link to="/">UPSC News</Link>
                                                            {Events &&
                                                                <ul style={{ display: "block" }}>
                                                                    <li>
                                                                        <Link to="/courses">Exam Calendar</Link>
                                                                    </li>
                                                                    <li>
                                                                        {" "}
                                                                        <Link to="/courses-2">UPSC Notification</Link>
                                                                    </li>
                                                                    <li>
                                                                        {" "}
                                                                        <Link to="/single-courses">UPSC Result</Link>
                                                                    </li>
                                                                    <li>
                                                                        {" "}
                                                                        <Link to="/single-courses-2">FAQs</Link>
                                                                    </li>
                                                                </ul>
                                                            }
                                                            <a className={`mean-expand ${mobile && "mean-clicked"}`} onClick={() => { setEvent(!Events) }} href="#" style={{ fontSize: 18 }}>
                                                                {Events ? "-" : "+"}
                                                            </a>
                                                        </li>
                                                        <li className="has-sub">
                                                            <Link to="/courses">IAS Exam</Link>
                                                            {News &&
                                                                <ul style={{ display: "block" }}>
                                                                    <li>
                                                                        <Link to="/courses">CSE Exam Plan</Link>
                                                                    </li>
                                                                    <li>
                                                                        {" "}
                                                                        <Link to="/courses-2">Syllabus</Link>
                                                                    </li>
                                                                    <li>
                                                                        {" "}
                                                                        <Link to="/single-courses">Books</Link>
                                                                    </li>
                                                                </ul>
                                                            }

                                                            <a className={`mean-expand ${mobile && "mean-clicked"}`} onClick={() => { setNews(!News) }} href="#" style={{ fontSize: 18 }}>
                                                                {News ? "-" : "+"}
                                                            </a>
                                                        </li>
                                                        <li className="has-sub">
                                                            <a href="#">How To Prepare</a>
                                                            {Services &&
                                                                <ul style={{ display: "block" }}>
                                                                    <li>
                                                                        <Link to="/event">Preliminary Examination</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="/single-event">Main Examination</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="/projects">Interview</Link>
                                                                    </li>
                                                                </ul>
                                                            }
                                                            <a className={`mean-expand ${mobile && "mean-clicked"}`} onClick={() => { setServices(!Services) }} href="#" style={{ fontSize: 18 }}>
                                                                {Services ? "-" : "+"}
                                                            </a>
                                                        </li>
                                                        <li className="has-sub">
                                                            <Link to="/blog">Blog</Link>
                                                            {Blog &&
                                                                <ul style={{ display: "block" }}>
                                                                    <li>
                                                                        <Link to="/blog">Blog</Link>
                                                                    </li>
                                                                    <li>
                                                                        <Link to="/blog-details">Blog Details</Link>
                                                                    </li>
                                                                </ul>
                                                            }
                                                            <a className={`mean-expand ${mobile && "mean-clicked"}`} onClick={() => { setblog(!Blog) }} href="#" style={{ fontSize: 18 }}>
                                                                {Blog ? "-" : "+"}
                                                            </a>
                                                        </li>
                                                        <li className="mean-last">
                                                            <Link to="/contact">Contact</Link>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {Btnshow &&
                <>
                    <div className={`offcanvas-menu ${"active"}`}>
                        <span className="menu-close" onClick={() => setBtnshow(false)}>
                            <i className="fas fa-times" onClick={() => setBtnshow(false)} />
                        </span>
                        <form role="search" method="get" id="searchform" className="searchform" action="http://wordpress.zcube.in/xconsulta/" >
                            <input type="text" name="s" id="search" placeholder="Search" />
                            <button>
                                <i className="fa fa-search" />
                            </button>
                        </form>
                        <div id="cssmenu3" className="menu-one-page-menu-container">
                            <ul className="menu">
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="/" className='fs-6'>Home</Link>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="#" className='d-flex justify-content-between fs-6 align-items-center' onClick={() => handlestorytoggle1()}>Upsc News <RiArrowDownSLine /></Link>
                                    {toggleopen2 && <> <ul className='p-3 pt-0 mt-0' style={{ background: "rgba(0,0,0,0.8)" }}>
                                        <li>
                                            <Link to="/" className='text-white fs-6'>Exam Calendar</Link>
                                        </li>
                                        <li>
                                            <Link to="/home-two" className='text-white fs-6'>UPSC Notification</Link>
                                        </li>
                                        <li>
                                            <Link to="/home-three" className='text-white fs-6'>UPSC Result</Link>
                                        </li>
                                        <li>
                                            <Link to="/home-three" className='text-white fs-6'>FAQs</Link>
                                        </li>
                                    </ul></> }
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="#" className='d-flex justify-content-between fs-6 align-items-center' onClick={() => handlestorytoggle2()}>IAS Exam <RiArrowDownSLine /></Link>
                                    {toggleopen3 && <><ul className='p-3 pt-0 mt-0' style={{ background: "rgba(0,0,0,0.8)" }}>
                                        <li>
                                            <Link to="/" className='text-white fs-6'>CSE Exam Plan</Link>
                                        </li>
                                        <li>
                                            <Link to="/home-two" className='text-white fs-6'>Syllabus</Link>
                                        </li>
                                        <li>
                                            <Link to="/home-three" className='text-white fs-6'>Books</Link>
                                        </li>
                                        <li>
                                            <Link to="/home-three" className='text-white fs-6'>Previous Year Question Paper</Link>
                                        </li>
                                    </ul> </>}
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="#" className='d-flex justify-content-between fs-6 align-items-center' onClick={() => handlestorytoggle3()}>How To Prepare <RiArrowDownSLine /></Link>
                                    {toggleopen4 && <><ul className='p-3 pt-0 mt-0' style={{ background: "rgba(0,0,0,0.8)" }}>
                                        <li>
                                            <Link to="/" className='text-white fs-6'>Preliminary Examination</Link>
                                        </li>
                                        <li>
                                            <Link to="/home-two" className='text-white fs-6'>Main Examination</Link>
                                        </li>
                                        <li>
                                            <Link to="/home-three" className='text-white fs-6'>Interview</Link>
                                        </li>
                                    </ul></>}
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="#" className='d-flex justify-content-between fs-6 align-items-center' onClick={() => handlestorytoggle4()}>Analysis & Interpretation <RiArrowDownSLine /></Link>
                                    {toggleopen5 && <><ul className='p-3 pt-0 mt-0' style={{ background: "rgba(0,0,0,0.8)" }}>
                                                        <li>
                                                            <Link to="/blog" className='text-white fs-6'>Cut-Off Marks</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/blog-details" className='text-white fs-6'>Exam Statistics</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/blog-details" className='text-white fs-6'>Question Papers Analysis</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/blog-details" className='text-white fs-6'>Success In First Attempt</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/blog-details" className='text-white fs-6'>Consistent Campaigner</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/contact" className='text-white fs-6'>Toppers Comer</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/contact" className='text-white fs-6'>News Views & Features</Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/contact" className='text-white fs-6'>Videos</Link>
                                                        </li>
                                                    </ul></>}
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="#" className='d-flex justify-content-between fs-6 align-items-center' onClick={() => handlestorytoggle5()}>IAS Success Story <RiArrowDownSLine /></Link>
                                   {toggleopen6 && <><ul className='p-3 pt-0 mt-0' style={{ background: "rgba(0,0,0,0.8)" }}>
                                        <li>
                                        <Link to="#" className='d-flex justify-content-between text-white fs-6 align-items-center' onClick={() => handlestorytoggle()}>Analysis and Evaluation <RiArrowDownSLine /></Link>
                                        </li>
                                        {toggleopen1 && <><li>
                                            <Link to="/home-two" className='text-white fs-6'>Daily MCQs/Trivia</Link>
                                        </li>
                                        <li>
                                            <Link to="/home-three" className='text-white fs-6'>Previous Years Questions</Link>
                                        </li></>}
                                    </ul></>}
                                </li>
                            </ul>
                        </div>
                        <div id="cssmenu2" className="menu-one-page-menu-container">
                            <ul id="menu-one-page-menu-12" className="menu mt-0">
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="#home" className='fs-6'>
                                        <span>+8 12 3456897</span>
                                    </Link>
                                </li>
                                <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                    <Link to="#howitwork" className='fs-6'>
                                        <span>info@iaspassion.com</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={`offcanvas-overly ${"active"}`}> </div>
                </>
            }
        </>
    )
}

export default Main