import React from 'react'
import Bredcom from '../Bredcom/Main'
import Header from '../headerone/Main'
import First from '../mencoursedetails/FirstSuccess'
import Brand from '../menhomethree/Brand'

function Main() {
  return (
    <>
        <Header/>
        <Bredcom title="Home" subtitle="IAS Success Story" />
        <First/>
        {/* <Brand/> */}
    </>
  )
}

export default Main