import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const faqItems = [
    {
        id: 'headingThree',
        question: 'What does IAS Passion Institute offer?',
        answer: 'The institute likely offers coaching programs for the UPSC Civil Services Examination (CSE), with a focus on General Studies Paper-I and other GS papers. They might also provide guidance on optional subjects, essay writing, and interview skills.',
    },
    {
        id: 'headingOne',
        question: 'Is IAS Passion Institute good for IAS preparation?',
        answer: 'The effectiveness of any coaching institute depends on individual learning styles and preferences. Consider factors like faculty experience, study materials, success rates (if available), and student reviews before making a decision.',
    },
    {
        id: 'headingTwo',
        question: 'What are the fees of IAS Passion Institute?',
        answer: 'Fee structures can vary depending on the program duration and type. Contact the institute directly for their latest fee information.',
    },
];

function First() {

    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <>
            <section className="project-detail">
                <div className="container">
                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-9 col-md-9 col-sm-12">
                                <h2>General Studies Paper-I</h2>
                                <div className="upper-box">
                                    <div className="single-item-carousel owl-carousel owl-theme">
                                        <figure className="image">
                                            <img src="assets/img/class/class-9.jpg" alt="" />
                                        </figure>
                                    </div>
                                </div>
                                <div className="inner-column">
                                    <div className="course-meta2 review style2 clearfix mb-30">
                                        <ul className="left">
                                            <li>
                                                <div className="author">
                                                    <div className="thumb">
                                                        <img src="assets/img/bg/testi_avatar.jpg" alt="image" />
                                                    </div>
                                                    <div className="text">
                                                        <Link to="/single-courses-2">Robto Jone</Link>
                                                        <p>Teacher</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="categories">
                                                <div className="author">
                                                    <div className="text">
                                                        <a href="#" className="course-name">
                                                            Photoshop
                                                        </a>
                                                        <p>Categories</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <ul className="right">
                                            <li className="price">&#x20b9; 59.00</li>
                                        </ul>
                                    </div>
                                    <h3>Course Overview</h3>
                                    <p>
                                    Welcome to the General Studies Paper-I section of IAS Passion Institute. This page is dedicated to providing comprehensive resources and guidance for one of the most crucial components of the Civil Services Main Examination.
                                    </p>
                                    <p>
                                    General Studies Paper-I covers a broad spectrum of subjects that are vital for understanding the socio-cultural and geographical aspects of India and the world. It tests the candidates’ knowledge and understanding of various topics including Indian heritage, culture, history, geography, and society. General Studies Paper-I encompasses a wide range of subjects, including Indian heritage and culture, history, geography, and society, which are essential for a deep understanding of India's socio-cultural and geographical landscape.
                                    </p>
                                    <h4>What You Will Learn</h4>
                                    <p>
                                    The syllabus for General Studies Paper-I of the IAS Exam is quite broad, and the specific content covered by Passion Institute might vary depending on their teaching style and approach. However, you can expect to learn about the following general topics in General Studies Paper-I for the IAS Exam.
                                    </p>
                                    <p>
                                    While the UPSC syllabus sets the framework, institutes like IAS Passion likely delve deeper into specific areas and provide additional resources to help you excel in General Studies Paper-I. 
                                    </p>
                                    <ul className="pr-ul">
                                        <li>
                                            <div className="icon">
                                                <i className="fal fa-check" />
                                            </div>
                                            <div className="text">
                                                Crawl accessibility so engines can read your website
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="fal fa-check" />
                                            </div>
                                            <div className="text">
                                                Share-worthy content that earns links, citations
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="fal fa-check" />
                                            </div>
                                            <div className="text">
                                                Keyword optimized to attract searchers &amp; engines
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="fal fa-check" />
                                            </div>
                                            <div className="text">
                                                Title, URL, &amp; description to draw high CTR
                                            </div>
                                        </li>
                                    </ul>
                                    <h4>Study Options:</h4>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                            <tr>
                                                <th>Qualification</th>
                                                <th>Length</th>
                                                <th>Code</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Bsc (Hons)</td>
                                                <td>3 years full time</td>
                                                <td>CDX3</td>
                                            </tr>
                                            <tr>
                                                <td>Bsc </td>
                                                <td>4 years full time</td>
                                                <td>CDX4</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h3>Frequently Asked Question</h3>
                                    <p>
                                    The UPSC Civil Services Exam (CSE) is a dream for many, and institutes like IAS Passion aim to help aspirants turn that dream into reality. Here are some commonly asked questions to shed light on what IAS Passion offers..
                                    </p>
                                    <div className="faq-wrap pt-30 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s">
                                        <div className="accordion" id="accordionExample">
                                            {faqItems.map((item, index) => (
                                                <div className="card" key={index}>
                                                    <div className="card-header" id={item.id}>
                                                        <h2 className="mb-0">
                                                            <button className={`faq-btn${activeIndex === index ? '' : ' collapsed'}`} type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={`#collapse${index}`}
                                                                onClick={() => handleToggle(index)}>
                                                                {item.question}
                                                            </button>
                                                        </h2>
                                                    </div>
                                                    <div id={`collapse${index}`}
                                                        className={`collapse${activeIndex === index ? ' show' : ''}`}
                                                        data-bs-parent="#accordionExample" >
                                                        <div className="card-body">{item.answer}</div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <aside className="sidebar-widget info-column">
                                    <div className="inner-column3">
                                        <h3>Course Features</h3>
                                        <ul className="project-info clearfix">
                                            <li>
                                                <div className="priceing">
                                                    <strong>&#x20b9; 59.00</strong> <sub>&#x20b9; 139.00</sub>
                                                    <span className="discont">68% OFF</span>
                                                </div>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-home-lg-alt" />{" "}
                                                <strong>Instructor:</strong> <span>Shiv Anand</span>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-book" />{" "}
                                                <strong>Lectures:</strong> <span>14</span>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-clock" />{" "}
                                                <strong>Duration: </strong> <span>6 weeks</span>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-user" />{" "}
                                                <strong>Enrolled: </strong> <span>20 students</span>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <strong>Language: </strong> <span>English</span>
                                            </li>
                                            <li>
                                                <div className="slider-btn">
                                                    <Link to="/contact" className="btn ss-btn smoth-scroll">
                                                        Enroll <i className="fal fa-long-arrow-right" />
                                                    </Link>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default First